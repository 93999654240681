/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import "@fontsource/sora";

import axios from "axios";
import Alpine from "alpinejs";
import Pusher from "pusher-js";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

if (import.meta.env.PROD) {
  window.axios.defaults.auth = {
    username: import.meta.env.VITE_AUTH_USERNAME,
    password: import.meta.env.VITE_AUTH_PASSWORD,
  };
}

window.Alpine = Alpine;

window.Pusher = Pusher;
