import axios from "axios";
import TrackerMap from "./model/tracker_map";
import $ from "cash-dom";
import MapFunctions from "./map_functions";
import colors from "../colors";
import laravelEcho from "../echo";
import { Marker, Popup } from "mapbox-gl";
import { DateTime } from "luxon";

const token = import.meta.env.VITE_MAPBOX_TOKEN || "";
const channelPrefix = import.meta.env.VITE_CHANNEL_PREFIX || "";

/*
TODO: App class

App.start()
class variables with data
*/

let adventure: Adventure;
let ongoing = true;

$(async function () {
  const mapCanvas = document.getElementById("map-canvas");
  if (mapCanvas === null) return;

  const res = await axios.get(
    `/api/adventures/${mapCanvas.dataset.adventureId}`
  );
  adventure = res.data;

  registerWeatherChannel(adventure.id);

  const center = mapCanvas.dataset.center
    ?.split(" ")
    .map((c) => parseFloat(c)) as [number, number] | undefined;

  ongoing = !!mapCanvas.dataset.ongoing;

  let opts = {};
  if (ongoing && center) {
    opts = {
      zoom: 13,
      center,
    };
  } else if (mapCanvas.dataset.bbox) {
    opts = {
      bounds: JSON.parse(mapCanvas.dataset.bbox || ""),
      fitBoundsOptions: {
        padding: 50,
      },
    };
  }

  const distanceUnits = mapCanvas.dataset["units"]!;
  const trackerMap = new TrackerMap(token, opts, distanceUnits);
  trackerMap.timezone = adventure.timezone;
  trackerMap.load(() => setup(trackerMap));
});

async function setup(map: TrackerMap) {
  const virtualRunners = await getVirtualrunners();
  const devices = await getDevices();
  const mainDevice = devices.find((dev) => dev.main_device);

  const mapCanvas = document.getElementById("map-canvas")!;
  const distanceUnits = mapCanvas.dataset["units"]!;
  activateDistanceMarkers(map, distanceUnits);

  mapCanvas.addEventListener("settingsupdated", (ev) => {
    const settings = (ev as CustomEvent).detail as {
      temperature: string;
      distance: string;
    };
    activateDistanceMarkers(map, settings.distance);
  });

  if (!mainDevice) return;

  prepare(map, virtualRunners, devices);
  loadData(map, devices);
}

function activateDistanceMarkers(map: TrackerMap, units: string) {
  if (units == "metric") {
    // map.map.setLayoutProperty("mile-markers-all", "visibility", "none");
    // map.map.setLayoutProperty("km-markers-all", "visibility", "visible");
    map.scale.setUnit("metric");
  } else {
    // map.map.setLayoutProperty("km-markers-all", "visibility", "none");
    // map.map.setLayoutProperty("mile-markers-all", "visibility", "visible");
    map.scale.setUnit("imperial");
  }
}

function prepare(
  map: TrackerMap,
  virtualRunners: VirtualRunner[],
  devices: TrackingDevice[]
) {
  const mainDevice = devices.find((dev) => dev.main_device);
  const otherDevices = devices.filter((dev) => !dev.main_device);

  map.sources = [
    "main-route",
    "virtual-runner-routes",
    "other-device-routes",
    "detour-routes",
    "main-device-route",
    "checkpoint-markers",
    "virtual-runner-markers",
    "other-device-markers",
    "main-device-markers",
  ].map((name) => ({
    name,
    source: {
      type: "geojson",
      data: MapFunctions.initGeojson(),
    },
    data: MapFunctions.initGeojson(),
  }));

  map.addSources();
  map.addMouseStates("main-device-markers");
  map.addClickHandlers("main-device-markers");

  setupMainRouteLayer(map);
  setupDetourLayer(map);
  setupCheckpointLayer(map);
  setupVRLayers(map, virtualRunners);
  setupOtherDeviceLayers(map, otherDevices);
  if (mainDevice) setupMainDeviceLayer(map, mainDevice);

  setLiveIndicator(devices);
}

async function loadData(map: TrackerMap, devices: TrackingDevice[]) {
  loadMainRoute(map);

  const mainDevice = devices.find((dev) => dev.main_device)!;
  const otherDevices = devices.filter((dev) => !dev.main_device);

  loadCheckpoints(map);

  const loadOthers = new Promise<void>((done) => {
    otherDevices.forEach(async (device) => {
      let marker: Marker | undefined;
      const tracking_points = await getTrackingPoints(device, 1);

      if (tracking_points.length > 0) {
        marker = map.putPhotoMarker(
          tracking_points[0].location.coordinates,
          device.name,
          device.photo,
          {
            size: "w-14 h-14",
          }
        );
        map.updatePopup(marker, tracking_points[0], device.name);
      }

      registerWS(`tracker.${device.id}`, map, marker);
    });

    mapPhotos(map)
      .then(() => mapVirtualRunners(map))
      .then(() => done());
  });

  loadDetours(map);

  let result = await getPagedTrackingPoints(mainDevice);
  if (result && result.data.length > 0) {
    map.trackingPoints = result.data;
    const currentPoint = map.trackingPoints[0];
    mapTrackingPoints(map, result.data);

    loadOthers.then(() => {
      const marker = map.putCurrentMarker(currentPoint, mainDevice, ongoing);
      map.updatePopup(marker, currentPoint, mainDevice.name);
      registerWS(`tracker.${mainDevice.id}`, map, marker);
    });
  }

  while (result.links.next) {
    result = await getPagedTrackingPoints(mainDevice, result.links.next);
    map.trackingPoints.push(...result.data);
    mapTrackingPoints(map, result.data);
  }
}

async function loadMainRoute(map: TrackerMap) {
  const result = await axios.get(`/api/adventures/${adventure.id}/route`);

  map.drawGeoJson("main-route", result.data);
}

async function loadCheckpoints(map: TrackerMap) {
  const result = await axios.get(`/api/adventures/${adventure.id}/checkpoints`);
  if (!result.data) return;

  const source = map.getSource("checkpoint-markers");
  if (source) {
    source.data.features = result.data;
  }

  map.updateSource("checkpoint-markers");
  result.data.forEach((checkpoint: any) => {
    map.putCheckpoint(checkpoint.geometry);
  });
}

async function loadDetours(map: TrackerMap) {
  const result = await axios.get(`/api/adventures/${adventure.id}/detours`);

  result.data.forEach((detour: Detour) => {
    map.drawGeoJson("detour-routes", JSON.parse(detour.route));
  });
}

function setupVRLayers(map: TrackerMap, virtualRunners: VirtualRunner[]) {
  virtualRunners.forEach((vr) => {
    map.addLayer({
      id: `vr-${vr.id}-border`,
      source: `virtual-runner-routes`,
      filter: ["==", ["id"], vr.id],
      paint: {
        "line-color": "#FFFFFF",
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 5,
          10, 6,
          20, 9],
        "line-opacity": 0.5,
      },
    });

    map.addLayer({
      id: `vr-${vr.id}`,
      source: `virtual-runner-routes`,
      filter: ["==", ["id"], vr.id],
      paint: {
        "line-color": colors["pct-green"]["600"],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 1,
          10, 2,
          20, 5],
        "line-opacity": 0.5,
      },
    });
  });
}

function setupOtherDeviceLayers(map: TrackerMap, devices: TrackingDevice[]) {
  devices.forEach((device) => {
    map.addLayer({
      id: `device-${device.id}-border`,
      source: "other-device-routes",
      filter: ["==", ["id"], device.id],
      paint: {
        "line-color": "#FFFFFF",
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 5,
          10, 6,
          20, 9],
        "line-opacity": 0.7,
      },
    });

    map.addLayer({
      id: `device-${device.id}`,
      source: "other-device-routes",
      filter: ["==", ["id"], device.id],
      paint: {
        "line-color": colors["pct-olive"]["600"],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 1,
          10, 2,
          20, 5],
        "line-opacity": 0.7,
      },
    });
  });
}

function setupCheckpointLayer(map: TrackerMap) {
  map.map.addLayer({
    id: "checkpoint-markers",
    type: "symbol",
    source: "checkpoint-markers",
    layout: {
      "icon-image": "flag-fill",
      // prettier-ignore
      "icon-size": ["interpolate", ["linear"], ["zoom"],
          0, 0.1,
          15, 0.8,
          20, 1],
      "icon-anchor": "bottom-left",
    },
    paint: {
      "icon-opacity": 0,
    },
  });

  const popup = new Popup({
    closeButton: false,
    anchor: "bottom",
    offset: [0, -5],
  });

  map.map.on("mouseenter", "checkpoint-markers", () => {
    map.map.getCanvas().style.cursor = "pointer";
  });

  map.map.on("click", "checkpoint-markers", (e) => {
    if (e && e.features) {
      const pt = e.features[0].geometry as GeoJSON.Point;
      const coordinates = pt.coordinates.slice();
      const description = e.features[0].properties?.name;
      popup
        .setLngLat(coordinates as [number, number])
        .setHTML(description)
        .addTo(map.map);
    }
  });

  map.map.on("mouseleave", "checkpoint-markers", () => {
    map.map.getCanvas().style.cursor = "";
  });
}

function setupMainDeviceLayer(map: TrackerMap, device: TrackingDevice) {
  map.addLayer({
    id: `main-device-border`,
    source: "main-device-route",
    paint: {
      "line-color": "#FFFFFF",
      // prettier-ignore
      "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 5,
        10, 6,
        20, 9],
      "line-opacity": 0.9,
    },
  });

  map.addLayer({
    id: `main-device`,
    source: "main-device-route",
    paint: {
      "line-color": colors["pct-red"]["600"],
      // prettier-ignore
      "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 1,
        10, 2,
        20, 5],
      "line-opacity": 1,
    },
  });

  map.map.addLayer({
    id: "main-device-markers",
    type: "circle",
    source: "main-device-markers",
    // prettier-ignore
    filter: [
      'case',
      ['>=', ['zoom'], 12], true,
      ['>=', ['zoom'], 10], ['==', ['%', ["get", "id"], 5], 0],
      ['>=', ['zoom'], 7], ['==', ['%', ["get", "id"], 20], 0],
      ['>=', ['zoom'], 6], ['==', ['%', ["get", "id"], 50], 0],
      false
  ],
    paint: {
      "circle-color": colors["pct-green"][600],
      // prettier-ignore
      "circle-radius": ["interpolate",
        ["exponential", 0.5], ["zoom"],
        0, 1,
        10, 3,
        20, 5
      ],
      "circle-stroke-width": 1,
      "circle-stroke-color": colors["pct-pink"][600],
    },
  });
}

function setupMainRouteLayer(map: TrackerMap) {
  map.addLayer({
    id: `main-route-border`,
    source: "main-route",
    paint: {
      "line-color": colors["orange"]["600"],
      // prettier-ignore
      "line-opacity": [
        "interpolate",
        ["exponential", 0.86],
        ["zoom"],
        0,0,
        10,0.6,
        22,0.6,
      ],
      // prettier-ignore
      "line-width": [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        13,5,
        14,5,
        15,5,
        18,15,
      ],
    },
  });

  map.addLayer({
    id: `main-route`,
    source: "main-route",
    paint: {
      "line-color": "black",
      // prettier-ignore
      "line-width": [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        13,2.5,
        14,3.5,
        15,3.5,
        18,8
      ],
      "line-opacity": 1,
      "line-dasharray": [
        "step",
        ["zoom"],
        ["literal", [1]],
        10,
        ["literal", [4, 3]],
        22,
        ["literal", [5, 3]],
      ],
    },
  });
}

function setupDetourLayer(map: TrackerMap) {
  map.addLayer({
    id: `detour-border`,
    source: "detour-routes",
    paint: {
      "line-color": colors["pct-green"][600],
      // prettier-ignore
      "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 3,
        10, 6,
        20, 9],
      "line-opacity": 0.7,
    },
  });

  map.addLayer({
    id: `detour`,
    source: "detour-routes",
    paint: {
      "line-color": colors["pct-pink"]["600"],
      // prettier-ignore
      "line-width": ["interpolate", ["linear"], ["zoom"],
        0, 1,
        10, 2,
        20, 5],
      "line-opacity": 1,
      "line-dasharray": [2, 3],
    },
  });
}

async function mapTrackingPoints(map: TrackerMap, points: TrackingPoint[]) {
  points.forEach((point, idx) => {
    map.putMarker(
      "main-device-markers",
      point,
      idx == map.trackingPoints.length - 1
    );
  });

  map.drawRoute();
}

async function mapPhotos(map: TrackerMap) {
  const result: any = await axios.get(`/api/adventures/${adventure.id}/photos`);
  result.data.forEach((photo: Photo) => {
    map.putPhoto(photo);
  });
}

async function mapVirtualRunners(map: TrackerMap) {
  const virtualRunners = await getVirtualrunners(true);
  const source = map.getSource("virtual-runner-routes");
  if (!source) return;

  virtualRunners.forEach((virtualRunner: VirtualRunner) => {
    let coords = virtualRunner.routes.map((l) => l.location.coordinates);
    if (coords.length === 0 && virtualRunner.oldestRoute) {
      coords = [virtualRunner.oldestRoute?.location.coordinates];
    }

    if (virtualRunner.photo) {
      let description = `<strong class="block mb-2">${virtualRunner.name}</strong>`;
      description += `<p class="whitespace-pre-line">${
        virtualRunner.description || ""
      }</p>`;

      const marker = map.putPhotoMarker(
        coords[0],
        description,
        virtualRunner.photo,
        {
          size: "w-12 h-12",
          style: "grayscale !border-2",
          tag: `${virtualRunner.year}`,
        }
      );
      registerVRWS(`virtual-runner.${virtualRunner.id}`, map, marker);
    }
  });
  map.updateSource("virtual-runner-routes");
}

async function getDevices(withPoints = false): Promise<TrackingDevice[]> {
  let result;

  if (withPoints) {
    result = await axios.get("/api/tracking-points");
  } else {
    result = await axios.get(
      `/api/adventures/${adventure.id}/tracking-devices`
    );
  }

  return result.data;
}

async function getVirtualrunners(withPoints = false): Promise<VirtualRunner[]> {
  const result = await axios.get(
    `/api/adventures/${adventure.id}/virtual-runners?points=${withPoints}`
  );

  return result.data;
}

async function getTrackingPoints(
  device: TrackingDevice,
  count: number
): Promise<TrackingPoint[]> {
  let url = `/api/tracking-devices/${device.id}/tracking-points`;
  if (count) {
    url += `?count=${count}`;
  }
  const result = await axios.get(url);

  return result.data;
}

async function getPagedTrackingPoints(
  device: TrackingDevice,
  page?: string
): Promise<PaginatedTrackingPoints> {
  let url = page || `/api/tracking-devices/${device.id}/tracking-points`;
  const result = await axios.get(url);

  return result.data;
}

async function setLiveIndicator(devices: TrackingDevice[]) {
  const liveDevices = devices.filter((device) => device.live_link);

  liveDevices.forEach((device) => {
    const div = document.createElement("div");
    div.innerHTML = `${device.name} is live!<br />`;
    div.innerHTML += `Check it out <a class="underline" target="_blank" href="${device.live_link}">here</a>`;
    const indicator = document.getElementById("live-indicator");
    if (indicator) {
      const span = indicator.querySelector("span");
      if (span) {
        span.innerHTML = "";
        span?.appendChild(div);
      }
      if (indicator.classList.contains("hidden")) {
        indicator.querySelector("button")?.click();
      }
    }
  });
}

async function removeLiveIndicator() {
  const indicator = document.getElementById("live-indicator");

  if (indicator) {
    const span = indicator.querySelector("span");
    if (span) span.innerHTML = "";
    if (!indicator.classList.contains("hidden"))
      indicator.querySelector("button")?.click();
  }
}

function registerWS(channel: string, map: TrackerMap, marker?: Marker) {
  laravelEcho
    .channel(`${channelPrefix}.${channel}`)
    .listen("TrackingPointAdded", (e: any) => {
      const trackingPoint: TrackingPoint = e.trackingPoint;

      if (e.isMainDevice) {
        const lastPoint = map.trackingPoints[0];

        if (
          DateTime.fromISO(trackingPoint.timestamp) <
          DateTime.fromISO(lastPoint.timestamp)
        ) {
          console.log("Discarding because new point earlier than last point");
          return;
        }

        map.trackingPoints.unshift(trackingPoint);
        map.putMarker("main-device-markers", trackingPoint);
        if (ongoing) {
          map.drawRoute();
        }
        if (map.isFocused()) map.panTo(trackingPoint.location.coordinates);
      } else {
        map.putMarker("other-device-markers", trackingPoint);
      }
      if (marker) {
        map.moveMarker(marker, trackingPoint);
        map.updatePopup(marker, trackingPoint, e.name);
      }
    });

  laravelEcho
    .channel(`${channelPrefix}.${channel}`)
    .listen("LiveLinkAdded", (e: any) => {
      const device: TrackingDevice = e.device;

      setLiveIndicator([device]);
    });

  laravelEcho
    .channel(`${channelPrefix}.${channel}`)
    .listen("LiveLinkRemoved", () => {
      removeLiveIndicator();
    });
}

function registerVRWS(channel: string, map: TrackerMap, marker?: Marker) {
  laravelEcho
    .channel(`${channelPrefix}.${channel}`)
    .listen("VirtualRunnerUpdated", (e: any) => {
      const point: Point = e.virtualRoute;

      if (marker) map.moveMarker(marker, point);
    });
}

function registerWeatherChannel(adventureId: number) {
  laravelEcho
    .channel(`${channelPrefix}.weather.${adventureId}`)
    .listen("WeatherUpdated", (e: any) => {
      const weather = e.weather;
      const el = document.getElementById("weather");

      if (el && weather) {
        const temp = Math.round(weather["current"]["temp"]);
        const unit = e.units == "metric" ? "C" : "F";
        el.innerHTML = `${temp} º${unit}`;
      }
    });
}
